body {
    font-family: Arial, sans-serif;
    background-color: #f5f5f5;
    margin: 0;
    padding: 0;
    font-size: 1.2rem;
}

.container {
    width: 70%;
    margin: 20px auto;
    background-color: #ffffff;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    box-sizing: border-box;
    page-break-inside: avoid;
}

/* Loader Style */
.loader {
    border: 6px solid #f3f3f3;
    border-top: 6px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    margin: 10px auto;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

h1, h2, h3 {
    color: #333;
}

h1 {
    margin-bottom: 20px;
}

h2 {
    margin-top: 20px;
    margin-bottom: 10px;
}

h3 {
    margin-bottom: 10px;
}

label {
    font-size: 14px;
    color: #333;
}

input[type="text"], select {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: none;
    border-radius: 4px;
    box-sizing: border-box;
}

input[type="text"]:focus, select:focus {
    border-color: #004080;
}

button {
    background-color: #004080;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    margin-top: 20px;
}

button:hover {
    background-color: #003366;
}

.add-item-button {
    background-color: #004080;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    margin-top: 10px;
}

.add-item-button:hover {
    background-color: #003366;
}

.submit-button {
    background-color: #28a745;
}

.submit-button:hover {
    background-color: #218838;
}

.table-container {
    width: 100%;
    overflow-x: auto;
}

.table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.table th, .table td {
    padding: 10px;
    border: 1px solid #ccc;
    text-align: left;
    vertical-align: top;
    word-wrap: break-word;
    white-space: normal;
    font-size: 1.2rem;
}

.table th {
    background-color: #004080;
    color: white;
}

.table td input[type="text"] {
    width: calc(100% - 20px);
}

.table td select {
    width: calc(100% - 20px);
}

@media (max-width: 768px) {
    .container {
        width: 90%;
        padding: 15px;
    }
}

@media (max-width: 480px) {
    .container {
        width: 100%;
        padding: 10px;
    }

    .table th, .table td {
        padding: 5px;
    }

    .table td input[type="text"], .table td select {
        width: calc(100% - 10px);
    }
}

.page-break {
    page-break-after: always;
}

.page-break-before {
    page-break-before: always;
    padding-top: 20mm; /* Top padding for subsequent pages */
}

.terms-container {
    page-break-before: always;
}

@media print {
    body {
        -webkit-print-color-adjust: exact;
        background-color: transparent; /* Remove background colors */
        padding-left: 5mm; /* Left padding for print */
        padding-right: 5mm; /* Right padding for print */
        font-size: 1.2rem; /* Base font size for print */
    }

    h1 {
        font-size: 1.2rem; /* Larger font size for h1 */
    }

    h2 {
        font-size: 1.2rem; /* Larger font size for h2 */
    }

    h3 {
        font-size: 1.2rem; /* Larger font size for h3 */
    }

    label, input[type="text"], select {
        font-size: 1.2rem; /* Adjust font size for labels and inputs */
    }

    .table th, .table td {
        font-size: 1.2rem; /* Adjust table text size */
    }

    .total-section td {
        font-size: 1.2rem; /* Larger font size for totals */
    }

    .container {
        width: 100%;
        background-color: transparent; /* Remove background color */
        box-shadow: none; /* Remove shadow */
    }

    .table-container-first {
        padding-left: 0mm;
        padding-right: 0mm;
        box-sizing: border-box;
    }

    .page-break {
        display: block;
        page-break-after: always;
    }

    .page-break-before {
        display: block;
        page-break-before: always;
        padding-top: 5mm; /* Top padding for subsequent pages */
    }

    .terms-container {
        page-break-before: always;
    }

    .avoid-page-break {
        page-break-inside: avoid;
    }

    .total-section,
    .terms-container,
    .container:last-child {
        page-break-inside: avoid;
    }

    .table th, .table td {
        background-color: transparent !important; /* Remove background colors */
        font-size: 1.2rem; /* Adjust for print */
    }

    h1 {
        font-size: 1.2rem; /* Smaller for print */
    }

    h2 {
        font-size: 1.2rem; /* Smaller for print */
    }

    h3 {
        font-size: 1.2rem; /* Smaller for print */
    }

    label, input[type="text"], select {
        font-size: 1.2rem; /* Smaller for print */
    }
}