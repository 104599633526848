body {
    font-family: Arial, sans-serif;
    background-color: #f5f5f5;
    margin: 0;
    padding: 0;
}

.container {
    width: 80%;
    margin: 20px auto;
    background-color: #ffffff;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    box-sizing: border-box;
}

h1, h2, h3 {
    color: #333;
}

h1 {
    margin-bottom: 20px;
}

h2 {
    margin-top: 20px;
    margin-bottom: 10px;
}

h3 {
    margin-bottom: 10px;
}

label {
    font-size: 14px;
    color: #333;
}

input[type="text"], select, textarea {
    width: 100%;
    padding: 12px;  /* Increased padding */
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 16px;  /* Increased font size */
}

input[type="text"]:focus, select:focus, textarea:focus {
    border-color: #004080;
}

button {
    background-color: #004080;
    color: white;
    padding: 12px 24px;  /* Increased padding */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;  /* Increased font size */
    margin-top: 20px;
}

button:hover {
    background-color: #003366;
}

.add-item-button {
    background-color: #004080;
    color: white;
    padding: 12px 24px;  /* Increased padding */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;  /* Increased font size */
    margin-top: 10px;
}

.add-item-button:hover {
    background-color: #003366;
}

.submit-button {
    background-color: #28a745;
}

.submit-button:hover {
    background-color: #218838;
}

.table-container {
    width: 100%;
    overflow-x: auto;
}

.table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.table th, .table td {
    padding: 10px;
    border: 1px solid #ccc;
    text-align: left;
    vertical-align: top;
    word-wrap: break-word; /* Ensures text wraps within the cell */
    white-space: normal; /* Allows text to wrap */
}

.table th {
    background-color: #004080;
    color: white;
}

.table td input[type="text"], .table td select {
    width: calc(100% - 20px);
}

@media (max-width: 768px) {
    .container {
        width: 90%;
        padding: 15px;
    }
}

@media (max-width: 480px) {
    .container {
        width: 100%;
        padding: 10px;
    }

    .table th, .table td {
        padding: 5px;
    }

    .table td input[type="text"], .table td select {
        width: calc(100% - 10px);
    }
}

.page-break {
    page-break-after: always;
}

.page-break-before {
    page-break-before: always;
}

.terms-container {
    page-break-before: always;
}

@media print {
    body {
        -webkit-print-color-adjust: exact;
    }

    .container {
        width: 100%;
    }

    .page-break {
        display: block;
        page-break-after: always;
    }

    .page-break-before {
        display: block;
        page-break-before: always;
    }

    .terms-container {
        page-break-before: always;
    }

    .avoid-page-break {
        page-break-inside: avoid;
    }

    .no-page-break {
        page-break-after: auto;
    }
}