body {
    font-family: Arial, sans-serif;
}

.container {
    width: 60%;
    margin: 0 auto;
    border: none;
    padding: 20px;
    box-sizing: border-box;
    page-break-inside: avoid;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.header .left {
    text-align: left;
    flex: 1;
}

.header .right {
    text-align: right;
}

.header .right img {
    max-width: 150px;
}

.details, .terms, .item-list {
    margin: 20px 0;
}

.details table, .terms table, .item-list table, .total-section table {
    width: 100%;
    border-collapse: collapse;
}

.details th, .details td, .terms th, .terms td, .item-list th, .item-list td, .total-section th, .total-section td {
    border: 1px solid #000;
    padding: 8px;
    text-align: left;
    word-wrap: break-word;
}

.details {
    table-layout: fixed;
}

.terms {
    padding: 16px;
    line-height: 1.6;
  }

.terms h3 {
    margin-bottom: 10px;
    color: #333;
}

.terms p {
    margin: 5px 0;
    color: #555;
}

.footer {
    text-align: center;
    margin-top: 40px;
    page-break-after: auto;
}

.total-section {
    margin-top: 20px;
}

.total-section table {
    margin-top: 20px;
}

@media (max-width: 768px) {
    .container {
        width: 100%;
        padding: 10px;
    }
    .header {
        flex-direction: column;
        align-items: flex-start;
    }
    .header .right {
        text-align: center;
        margin-top: 10px;
    }
}

@media (max-width: 480px) {
    .details th, .details td, .item-list th, .item-list td, .total-section th, .total-section td {
        padding: 5px;
    }
    .terms p {
        margin: 5px 0;
    }
}

@media print {
    body {
        -webkit-print-color-adjust: exact;
    }
    .container {
        width: 100%;
        padding: 20px; /* Add padding for print */
        margin: 0;
        border: none;
        box-sizing: border-box;
        page-break-inside: avoid;
        font-size: 1.2rem;
    }
    .header {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .header .right {
        text-align: right;
        margin-top: 0;
    }
    .page-break {
        page-break-before: always;
    }
    .footer {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        border-top: 1px solid #000;
        padding: 10px;
        box-sizing: border-box;
        background: white;
        font-size: 1.2rem;
    }
    .total-section {
        page-break-inside: avoid;
    }
    .total-section table {
        width: 100%;
        border-collapse: collapse;
        margin-top: 20px;
        font-size: 1.2rem;
    }
    .total-section th, .total-section td {
        border: 1px solid #000;
        padding: 8px;
        text-align: left;
        word-wrap: break-word;
        font-size: 1.2rem;
    }
}