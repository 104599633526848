.page-container {
    display: flex;
    height: 100vh;
    background-color: #f7f9fc;
  }
  
  .page-content {
    display: flex;
    width: 100%;
  }
  
  .left-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    padding: 2rem;
  }
  
  .right-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    background: url('https://qms.dotnetiks.com/assets/bg2.png') no-repeat center center;
    background-size: cover;
  }
  
  .login-logo {
    width: 150px;
    margin-bottom: 2rem;
    background-color: #000;
    border-radius: 30px;
  }
  
  .welcome-back {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  .subtitle {
    font-size: 1rem;
    color: #555;
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .login-container {
    display: flex;
    justify-content: center;
  }
  
  .login-box {
    width: 100%;
    padding: 2rem;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  .login-title {
    text-align: center;
    margin-bottom: 1rem;
  }
  
  .login-subtitle {
    text-align: center;
    color: #777;
    margin-bottom: 2rem;
  }
  
  .login-input {
    margin-bottom: 1rem;
  }
  
  .login-button {
    background-color: #000;
    color: #fff;
    margin-top: 1rem;
  }
  
  .toggle-link {
    font-size: large;
    text-align: center;
    margin-top: 1rem;
    cursor: pointer;
    color: #007bff;
  }
  
  .toggle-link a {
    color: inherit;
    text-decoration: none;
  }
  
  .footer-text {
    text-align: center;
    margin-top: 2rem;
    color: #555;
  }
  
  .footer-text a {
    color: #007bff;
    text-decoration: none;
  }
  
  .right-logo {
    width: 150px;
  }
  
  @media (max-width: 768px) {
    .page-content {
      flex-direction: column;
    }
  
    .left-container, .right-container {
      width: 100%;
    }
  
    .right-container {
      height: 300px;
    }
  
    .login-box {
      padding: 1rem;
    }
  
    .login-logo {
      width: 120px;
      background-color: #000;
      border-radius: 30px;
    }
  
    .welcome-back {
      font-size: 1.5rem;
    }
  
    .subtitle {
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 480px) {
    .login-logo {
      width: 100px;
      background-color: #000;
      border-radius: 30px;
    }
  
    .welcome-back {
      font-size: 1.2rem;
    }
  
    .subtitle {
      font-size: 0.8rem;
    }
  
    .login-title {
      font-size: 1.2rem;
    }
  
    .login-subtitle {
      font-size: 0.8rem;
    }
  
    .login-input {
      font-size: 0.9rem;
    }
  
    .login-button {
      font-size: 0.9rem;
    }
  }